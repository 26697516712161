import React from 'react';
import PropTypes from 'prop-types';
import { useElasticSearch } from './ElasticSearch';

import ToggleCheckbox from '../ui/ToggleCheckbox';

const QuickDeliveryToggle = ({ isDesktopVersion }) => {
  const elasticSearch = useElasticSearch();

  return (
    <div style={{ flexShrink: 0, marginTop: '3px' }}>
      <ToggleCheckbox
        name="quick_delivery"
        value="true"
        label="Quick delivery"
        labelPosition="left"
        isDesktopVersion={isDesktopVersion}
        defaultChecked={
          !!elasticSearch.props.selectedFilters && elasticSearch.props.selectedFilters.quickDelivery === 'true'
        }
        setSingleCheckboxFilter={elasticSearch.props.setSingleCheckboxFilter}
        selectedFilters={elasticSearch.props.selectedFilters}
      />
    </div>
  );
};

QuickDeliveryToggle.propTypes = {
  isDesktopVersion: PropTypes.bool.isRequired,
};

export default QuickDeliveryToggle;
